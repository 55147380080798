import { isBrowser } from '@utils/isBrowser';
import { useEffect, useState } from 'react';

import { BREAKPOINTS } from '../constants/breakpoints';

const queries = {
  sm: `(max-width: ${BREAKPOINTS.MD - 1}px)`,
  md: `(min-width: ${BREAKPOINTS.MD}px)`,
  lg: `(min-width: ${BREAKPOINTS.LG}px)`,
};

const getMatches = (query: string): boolean => {
  if (isBrowser()) {
    return window.matchMedia(query).matches;
  }
  return false;
};

export const useMediaQuery = (
  breakpoint: 'sm' | 'md' | 'lg',
  custom?: string,
): boolean => {
  const query = custom || queries[breakpoint];
  const [matches, setMatches] = useState<boolean>(false); // for SSR this use state can't run getMatches by default even with the typeof checks

  const handleChange = () => {
    setMatches(getMatches(query));
  };

  useEffect(() => {
    const matchMedia = window.matchMedia(query);
    handleChange();

    matchMedia.addEventListener('change', handleChange);
    return () => {
      matchMedia.removeEventListener('change', handleChange);
    };
  }, [query]);

  return matches;
};
