import { createContext, useEffect, useMemo, useState } from 'react';

type ViewportDimensions = {
  width: number;
  height: number;
};

type ViewportContextType = {
  scrollX: number;
  scrollY: number;
  dimensions: ViewportDimensions;
};

export const ViewportContext = createContext<ViewportContextType>({
  scrollX: 0,
  scrollY: 0,
  dimensions: {
    width: 0,
    height: 0,
  },
});

export const ViewportProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [scrollX, setScrollX] = useState(0);
  const [scrollY, setScrollY] = useState(0);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const handleScroll = () => {
      setScrollX(window.scrollX);
      setScrollY(window.scrollY);
    };

    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    window.addEventListener('resize', handleResize, { passive: true });
    handleResize();
    handleScroll();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const context = useMemo(
    () => ({ dimensions, scrollX, scrollY }),
    [dimensions, scrollX, scrollY],
  );

  return (
    <ViewportContext.Provider value={context}>
      {children}
    </ViewportContext.Provider>
  );
};
