import { GeneratedGenerateAvantPassTokenInput } from './generated';
import { AttributeInput } from './Purchasing';

export type LoginAndSignUpRequest = {
  email: string;
};

export type LoginAndSignUpResponse = {
  state: AuthState;
  error?: AuthError;
};

export type CodeValidationRequest = {
  email: string;
  code: string;
};

export type CodeValidationResponse = {
  state: AuthState;
  error?: AuthError;
};

export type GoogleLoginResponse = {
  state: AuthState;
  isNewAccount?: boolean;
  error?: AuthError;
};

export type LogoutResponse = {
  state: AuthState;
  error?: AuthError;
};

export type AuthError = {
  code:
    | 'EMAIL_ALREADY_TAKEN'
    | 'EMAIL_NOT_FOUND'
    | 'UNAUTHORIZED'
    | 'UNKNOWN_ERROR'
    | 'RATE_LIMIT_HIT'
    | 'CODE_EXPIRED';
};

export type AuthErrorResponse = {
  error: AuthError;
};

export enum AuthState {
  LOGGED_OUT = 'LOGGED_OUT',
  CODE_PENDING = 'CODE_PENDING',
  LOGGED_IN = 'LOGGED_IN',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
}

export type AuthGateway = {
  generateShopifyMultipassToken(
    returnTo: string,
  ): Promise<GenerateMultipassTokenResponse | AuthErrorResponse>;
  generateAvantPassToken(
    input: GeneratedGenerateAvantPassTokenInput,
  ): Promise<GenerateAvantPassTokenResponse>;
};

export type GenerateMultipassTokenRequest = {
  returnTo: string;
};

export type GenerateMultipassTokenResponse = {
  token: string;
};

export type GenerateAvantPassTokenResponse = {
  token: string;
  customAttribute: AttributeInput;
};
