import { ApolloClient, from, HttpLink, HttpOptions } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import { isBrowser } from '@utils/isBrowser';
import fetch from 'cross-fetch';
import { GetServerSidePropsContext, GetStaticPropsContext } from 'next';

import { captureError } from '../../utils/capture';

const httpOpts: HttpOptions = {
  credentials: 'include',
  uri: (operation) =>
    `${process.env.NEXT_PUBLIC_API_ENDPOINT}?op=${operation.operationName}`,
  fetch,
};

const errorLink = onError(({ forward, graphQLErrors, operation }) => {
  if (!graphQLErrors) return forward(operation);

  graphQLErrors.forEach((err) => {
    if (err.originalError) {
      captureError(err.originalError);
    }
  });

  return undefined;
});
/*
  ssrClient is an apollo client using AA GQL API. Should be used for SSR(server side) pages.
  It picks up user session(auth/ip/ratelimit,..) via Next.js context.
*/
export const ssrClient = (ctx: GetServerSidePropsContext) => {
  const { headers } = ctx.req;

  // Vercel prevents IP spoofing by overwriting the client IP
  // https://vercel.com/docs/concepts/edge-network/headers#x-forwarded-for
  let userIP = headers['x-forwarded-for'] as string;

  // if request is coming from Cloudflare, use cf-connecting-ip as IP
  // we verify the authenticity of request by checking for manually added header X-CDN-VERIFY
  // alternatively we can verify the ip ranges of the CF network but that is prone to change
  // https://developers.cloudflare.com/fundamentals/get-started/reference/http-request-headers/#cf-connecting-ip
  if (
    headers['cf-connecting-ip'] &&
    headers['x-cdn-verify'] === '012a02f918d04c0dac6b584227400a76'
  ) {
    userIP = headers['cf-connecting-ip'] as string;
  }

  const httpOptsHeaders: HttpOptions = {
    ...httpOpts,
    headers: {
      Cookie: headers.cookie || '',

      'X-Nextjs-Verify': process.env
        .GQL_API_IP_FORWARDED_HEADER_VERIFY as string,
      'X-Nextjs-Forwarded-For': userIP,

      // optional headers
      'X-User-Agent': headers['user-agent'] as string,
      'Sec-CH-UA': headers['sec-ch-ua'] as string,
      'Sec-CH-UA-Mobile': headers['sec-ch-ua-mobile'] as string,
      'Sec-CH-UA-Platform': headers['sec-ch-ua-platform'] as string,
      'Sec-CH-UA-Platform-Version': headers[
        'sec-ch-ua-platform-version'
      ] as string,
    },
  };

  return new ApolloClient({
    ssrMode: true,
    cache: new InMemoryCache(),
    link: from([errorLink, new HttpLink(httpOptsHeaders)]),
  });
};

type ExactGetStaticPropsContext<T> = T &
  Exact<T, GetStaticPropsContext, 'Exact GetStaticPropsContext type needed'>;

/*
  ssgClient is an apollo client using AA GQL API. Should be used for ISR(incremental) or SSG(static) pages.
  It has a higher rate limiting applied on API side.
*/
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ssgClient = <T>(_: ExactGetStaticPropsContext<T>) => {
  const httpOptsHeaders: HttpOptions = {
    ...httpOpts,
    headers: {
      'X-Nextjs-Verify': process.env
        .GQL_API_IP_FORWARDED_HEADER_VERIFY as string,
      'X-Nextjs-Build': 'true',
    },
  };

  return new ApolloClient({
    ssrMode: true,
    cache: new InMemoryCache(),
    link: from([errorLink, new HttpLink(httpOptsHeaders)]),
  });
};

/*
  ssgClient is an apollo client using AA GQL API. Should be used for client side requests in browser.
*/
export const csrClient = new ApolloClient({
  ssrMode: false,
  cache: new InMemoryCache(),
  connectToDevTools: true,
  link: from([errorLink, new HttpLink(httpOpts)]),
});

// this will work for XHR requests sent from arte.bio domain
export const arteBioClient = new ApolloClient({
  ssrMode: false,
  cache: new InMemoryCache(),
  connectToDevTools: true,
  link: from([
    errorLink,
    new HttpLink({
      ...httpOpts,
      ...(isBrowser() && window.location.hostname === 'arte.bio'
        ? { uri: (op) => `https://api.arte.bio/query?op=${op.operationName}` }
        : {}),
    }),
  ]),
});
