import React, { createContext, useEffect } from 'react';

import { useUnsafeInternalEmitter } from './emitter';
import { Map, PropsWithEmitterChild } from './types';

export interface AnalyticsFlowContext {
  name: string;
  properties?: Map;
  sendViewedEvent?: boolean;
}

export interface AnalyticsFlowProviderProps extends AnalyticsFlowContext {}

export const AnalyticsFlow = createContext<AnalyticsFlowContext | null>(null);

export const AnalyticsFlowProvider: React.FC<
  PropsWithEmitterChild<AnalyticsFlowProviderProps>
> = (props) => {
  const { children, name, properties, sendViewedEvent } = props;
  const [emitter] = useUnsafeInternalEmitter({
    flowOverride: { name, properties },
  });

  useEffect(() => {
    if (sendViewedEvent) emitter('flow', 'viewed');
  }, [name, sendViewedEvent]);

  return (
    <AnalyticsFlow.Provider value={{ name, properties }}>
      {children(emitter)}
    </AnalyticsFlow.Provider>
  );
};
