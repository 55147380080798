import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

export interface StepAnalytics {
  name: string;
  properties?: Record<string, string>;
}

export interface Step {
  id: string;
  title?: string | React.ReactNode;
  content: React.ReactNode;
  footer?: React.ReactNode;
  analytics: StepAnalytics;
  hideBackButton?: boolean;
  backStepId?: string;
  // Inactive steps are not rendered by default unless forced, for example useful for Stripe elements
  forceRender?: boolean;
  hideSpacing?: boolean;
  hideHeader?: boolean;
  hideCloseButton?: boolean;
  isProgressIndicatorVisible?: boolean;
}

export interface FlowConfig {
  containerId?: string;
  name: string;
  steps: Step[];
  analyticsProperties?: Record<string, string>;
  hideCloseButton?: boolean;
  onFlowComplete?: () => void;
  isProgressIndicatorVisible?: boolean;
}

interface FlowContextProps {
  closeFlow: () => void;
  currentStepId?: string | null;
  currentStepIndex?: number;
  flowConfig: FlowConfig | null;
  goNextStep: () => void;
  goPrevStep: () => void;
  goToStep: (id: string) => void;
  isFlowShown: boolean;
  startFlow: (config: FlowConfig) => void;
}

const FlowContext = createContext<FlowContextProps>({
  closeFlow: () => {},
  flowConfig: null,
  goNextStep: () => {},
  goPrevStep: () => {},
  goToStep: () => {},
  isFlowShown: false,
  startFlow: () => {},
});

export const FlowProvider = ({ children }: { children: React.ReactNode }) => {
  const [isFlowShown, setIsFlowShown] = useState(false); // This is needed to correctly show transition states
  const [flowConfig, setFlowConfig] = useState<FlowConfig | null>(null);
  const [currentStep, setCurrentStep] = useState<string | null>();

  const currentStepIndex = flowConfig
    ? flowConfig.steps.findIndex((s) => s.id === currentStep)
    : -1;

  const startFlow = useCallback((config: FlowConfig) => {
    if (config.steps.length === 0) {
      throw Error('Steps cannot be empty');
    }

    setFlowConfig({ containerId: 'default', ...config });
    setCurrentStep(config.steps[0].id);
    setIsFlowShown(true);
  }, []);

  const closeFlow = useCallback(() => {
    setIsFlowShown(false);

    // Need to delay the reset of the config/steps to allow time for the exit transition of the dialog
    setTimeout(() => {
      setCurrentStep(null);
      setFlowConfig(null);
      flowConfig?.onFlowComplete?.();
    }, 500);
  }, [flowConfig]);

  const goNextStep = useCallback(() => {
    if (flowConfig === null) return;

    const nextStep = flowConfig.steps[currentStepIndex + 1];

    if (!nextStep) {
      // We have reached the end of the steps, we can complete the flow
      closeFlow();
      return;
    }

    setCurrentStep(nextStep.id);
  }, [flowConfig, currentStepIndex, closeFlow]);

  const goPrevStep = useCallback(() => {
    if (flowConfig === null) return;
    const prevStep = flowConfig.steps[currentStepIndex - 1];

    if (!prevStep) {
      return;
    }

    setCurrentStep(prevStep.id);
  }, [flowConfig, currentStepIndex]);

  const goToStep = useCallback(
    (id: string) => {
      if (flowConfig === null) return;
      const step = flowConfig.steps.find((s) => s.id === id);

      if (!step) {
        goPrevStep();
        return;
      }

      setCurrentStep(step.id);
    },
    [flowConfig, goPrevStep],
  );

  const context = useMemo(
    () => ({
      currentStepId: currentStep,
      currentStepIndex,
      closeFlow,
      flowConfig,
      goNextStep,
      goPrevStep,
      goToStep,
      isFlowShown,
      startFlow,
    }),
    [
      currentStep,
      currentStepIndex,
      closeFlow,
      flowConfig,
      goNextStep,
      goPrevStep,
      goToStep,
      isFlowShown,
      startFlow,
    ],
  );

  return (
    <FlowContext.Provider value={context}>{children}</FlowContext.Provider>
  );
};

export const useFlow = () => useContext(FlowContext);
